<template>
  <v-app>
    <div class="fill-height d-flex align-center justify-center" v-if="loading">
      <v-progress-circular large indeterminate color="primary" />
    </div>
    <v-container v-if="!loading" ref="content">
      <v-tabs class="mb-4">
        <v-tab style="font-weight: 500"
          >{{$t('vitals-check for-patient-with-id:')}} <br />{{ identifier }}</v-tab
        >
      </v-tabs>
      <render-device-data
        v-for="(item, i) in assignedMedicalDevices"
        :key="i"
        :item="item"
        @show="showItem(item)"
        @hide="hideItem(item)"
      />
      <div
        v-if="assignedMedicalDevices === undefined"
        class="pa-6"
        style="
          backgroundcolor: transparent;
          border: 1px dashed #E8E8E8;
          border-radius: 5px;
        "
      >
        <span class="card_color--text pa-2"
          depressed
          outlined
          style="
            border: solid #E8E8E8;
            background-color: transparent !important;
            border-radius: 8px;
          "
          >{{ $t("thisUserHasNoDevices") }}</span
        >
      </div>
    </v-container>
  </v-app>
</template>
<script>
import { mapState } from 'vuex';
import RenderDeviceData from './RenderDeviceData.vue';
// import blood from "@/assets/devices/blood-pressure.svg";
// import glucose from "@/assets/devices/glucose.svg";
// import oximeter from "@/assets/devices/pulse-oximeter.svg";
// import temperature from "@/assets/devices/heat.svg";
// import weight from "@/assets/devices/scales.svg";
// import cholesterol from "@/assets/devices/cholesterol.svg";
import Login from '@/data/models/Login';
export default {
	components: {
		RenderDeviceData,
		// LineChart,
	},
	data () {
		return {
			selectedItem: 1,
			loading: true,
			hide: {
				temp: false,
				oxi: false,
				blood: false,
				glu: false,
				chol: false,
				scale: false,
			},
			items: [{ hidden: true }, { hidden: true }],
		};
	},
	computed: {
		...mapState({
			bloodPresure: (state) => state.medicaldevice.bloodPresure,
			hubs: (state) => state.nosessionhubs.hubs,
		}),
		identifier () {
			return this.hubs.hubIdentifier;
		},
		assignedMedicalDevices () {
			return this.hubs.assignedMedicalDevicesList?.map((item) => ({
				...item,
				img: this.getDeviceImg(item.deviceType),
				icon: this.getDeviceIcon(item.deviceType),
				hide: this.getDeviceStatus(item.deviceType),
			}));
		},
		deviceName () {
			return this.bloodPresure.deviceName;
		},
	},
	methods: {
		hideItem (item) {
			switch (item.deviceType) {
			case 1:
				this.hide.temp = true;
				break;
			case 2:
				this.hide.oxi = true;
				break;
			case 3:
				this.hide.blood = true;
				break;
			case 5:
				this.hide.scale = true;
				break;
			case 6:
				this.hide.glu = true;
				break;
			case 7:
				this.hide.chol = true;
				break;
			}
		},
		showItem (item) {
			switch (item.deviceType) {
			case 1:
				this.hide.temp = false;
				break;
			case 2:
				this.hide.oxi = false;
				break;
			case 3:
				this.hide.blood = false;
				break;
			case 5:
				this.hide.scale = false;
				break;
			case 6:
				this.hide.glu = false;
				break;
			case 7:
				this.hide.chol = false;
				break;
			}
		},
		getDeviceStatus (i) {
			var t = null;
			switch (i) {
			case 1:
				t = this.hide.temp;
				break;
			case 2:
				t = this.hide.oxi;
				break;
			case 3:
				t = this.hide.blood;
				break;
			case 5:
				t = this.hide.scale;
				break;
			case 6:
				t = this.hide.glu;
				break;
			case 7:
				t = this.hide.chol;
				break;
			}
			return t;
		},
		getDeviceIcon (i) {
			switch (i) {
			case 1:
				return 'mdi-thermometer';
			case 2:
				return 'mdi-gauge';
			case 3:
				return 'mdi-water';
			case 5:
				return 'mdi-scale-bathroom';
			default:
				return 'mdi-tablet-cellphone';
			}
		},
		getDeviceImg (i) {
			switch (i) {
			// case 1:
			//   return temperature;
			// case 2:
			//   return oximeter;
			// case 3:
			//   return blood;
			// case 5:
			//   return weight;
			// case 6:
			//   return glucose;
			// case 7:
			//   return cholesterol;
			default:
				return '';
			}
		},
		async getHubsData (id) {
			await this.$store.dispatch('nosessionhubs/getHubsData', id);
			this.loading = false;
		},
		async login (username, password) {
			const login = new Login({
				email: username,
				password: password,
			});
			await this.$store.dispatch('authentication/doLogin', login);
		},
	},
	async mounted () {
		const data = {
			id: 6,
			filter: 'LAST7',
		};
		// await this.login('arb', '123456789')
		await this.getHubsData(data);
	},
};
</script>
